import React from 'react';
import { Stack } from '@mui/material';
import { Text, breakpoints, v2Colors, LinkOverlay, LinkOverlayContainer } from '@web-for-marketing/react-ui';
import { StatsHighlightsWidget } from '@models/widget';
import { RichTextContent } from '@components/RichTextContent';
import { hasValue } from '@helpers/operand';

interface StatsHighlightsProps {
    widget: StatsHighlightsWidget;
    className?: string;
}

const classes = {
    container: {
        display: 'flex',
        width: '100%',
        gap: '0.8rem',
        boxSizing: 'border-box',
        flexDirection: 'column',
        [`@media (min-width: ${breakpoints.md})`]: {
            gap: '1.2rem',
            flexDirection: 'row',
        },
    },
    // a stat card should take up at least 50% of it's parent and can grow up to 100% if there is only one stat
    // on mobile it should always be 100%
    statItem: {
        flexBasis: '100%',
        minWidth: '50%',
        [`@media (min-width: ${breakpoints.md})`]: {
            flexBasis: 'auto',
        },
    },
    statCard: {
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '4px',
        padding: '1.2rem 1.6rem',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    cardLink: {
        transition: 'border-color 0.2s',
        '&:focus-within': {
            outline: `1px solid ${v2Colors.border.activePrimary}`,
            outlineOffset: '3px',
        },
        '&:hover': {
            borderColor: v2Colors.border.activePrimary,
        },
    },
    srOnly: {
        border: '0px',
        clip: 'rect(0px, 0px, 0px, 0px)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0px',
        whiteSpace: 'nowrap',
        width: '1px',
        outline: 'none',
    },
} as const;

export function StatsHighlights({ widget, className }: StatsHighlightsProps): JSX.Element {
    return (
        <div className={className} data-testid='stats-highlights' css={classes.container}>
            {widget.content.stats.map((stat, index) => {
                const isLink = hasValue(stat.url) && hasValue(stat.ariaLabel);

                return (
                    <div key={index} css={classes.statItem}>
                        <LinkOverlayContainer css={[classes.statCard, isLink ? classes.cardLink : undefined]}>
                            {isLink ? (
                                <LinkOverlay
                                    href={stat.url}
                                    isExternal={stat.shouldOpenNewTab}
                                    css={classes.srOnly}
                                    className={stat.className}
                                >
                                    {stat.ariaLabel}
                                </LinkOverlay>
                            ) : null}
                            <Stack direction='row' spacing='1.8rem' alignItems='center'>
                                <Text variant='h2' weight='bold' color='activeSecondary'>
                                    {stat.unit}
                                </Text>
                                <RichTextContent
                                    variant='body3'
                                    color='secondary'
                                    data-testid='stats-highlights-description'
                                    content={stat.description}
                                />
                            </Stack>
                        </LinkOverlayContainer>
                    </div>
                );
            })}
        </div>
    );
}
